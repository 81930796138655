import {red, indigo, yellow, cyan, lightGreen, blueGrey} from '@material-ui/core/colors/';

const color = blueGrey;

const theme = {
  palette: {
    primary: color,
    secondary: red
  },
  typography: {
    fontSize: 16,
    h1 : {
      fontSize: "3rem",
      fontWeight: 500,
      color: red[900]
    }
  },
  button: {
    color: "primary",
    variant: "contained"
  },
  postGridItemPadding: '16px',
  headingColor: indigo,
  highlightOne: yellow,
  highlightTwo: cyan,
  highlightThree: lightGreen
}

export default theme